import { Controller, Application } from "stimulus"
const application = Application.start()

application.register("clipboard", class extends Controller {
  static targets = ["source"]
  copy() {
    let url = this.sourceTarget.dataset.url
    this.createDummyAndCopy(url)
    this.sourceTarget.textContent = "Copied!"
  }

  createDummyAndCopy(url) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = url;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
})